<template>
  <DialogFormWrapper hide-actions>
    <template #form>
      <AddCommune />
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import AddCommune from './Partials/AddCommune.vue'

export default {
  components: {
    DialogFormWrapper,
    AddCommune,
  },
}
</script>
